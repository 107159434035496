/* :root {
    --background: white;
    --ff--body: 'Heebo', sans-serif;
    --ff-heading: 'Oswald', sans-serif;
    --clr: #006194;
    --clr-primary-400: hsla(50, 100%, 50%, 1);
    --clr-neutral-900: hsl(0 0% 0%);
    --clr-neutral-100: hsl(0 0% 100%);
    --clr-purple-pastel: hsl(272,100%,46.7%);
    --clr-pink-pastel: hsl(302.4,100%,48.8%);
    --clr--dark-gray: hsl(0,0%,66.3%);
    --clr--pink-base: hsl(15,100%,93.7%);
    --clr--pink-bottom: hsl(45,100%,93.7%);
    --fs-xl: clamp(3rem, 1rem + 10vw, 8rem);
    --fs-600: 2rem;
    --fs-500: 1.5rem;
    --fs-400: 1.125rem;
}

 */

*, *::before, *::after {
    box-sizing: border-box;
}

body,
p,
h1,
h2,
h3, h4, blockquote {
    margin: 0;
    padding: 0;
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

img {
  width: 100%;
}

ol,li {
  list-style-type: none;
  font-weight:400;
}
.listHeading {
  margin-top:2rem;
  font-weight:700;
}

.pdf {
  width: 20%;
}

.bookcoverLarge {
  width:20rem;
}

.resourceStar {
  width:30rem;
}

.row {
  margin: 2rem 2rem 4rem 2rem;
}


.frontpage {
    font-size: 3.5rem;
}



img {
    display: block;
    max-width: 100%;
}

.flow > * + * {
    margin-top: var(--flow-spacer, 1rem);
    text-align:left;
}

body {
    font-family: 'Heebo', sans-serif;
    font-size: 1.125rem;
    line-height: 1.6;
}

h1,
.lead,
blockquote {
    font-family:  'Oswald', sans-serif;
}

h1 {
    font-size: clamp(3rem, 1rem + 10vw, 8rem);
    text-transform: uppercase;
    font-weight: 900;
    line-height: 1;
}

blockquote {
    font-size: 2rem;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 1em;
}

blockquote::before {
    content: open-quote;
}

blockquote::after {
    content: close-quote;
}

article {
    padding-top: 100px;
    display:grid;
}

article > *:not(img) {
    padding: 0 2rem;
}

.lead {
    font-size: 1.5rem;
    max-width: 40ch;
}

.article-body {
    columns: 2 20ch;
    column-rule: 2px solid red;
    gap: 4rem;
    max-width: 100ch;
}

.article-body > p:first-child::first-letter{
    font-size: 5rem;
    float:left;
    display: block;
    margin: -0.2rem 0.2rem -0.2rem 0;
    line-height: 4.9rem;
    font-weight: 750;
    padding:0 0.2rem 0 0.2rem;
}

.article-body > p:first-child::first-line{
    font-weight: 750;
}

.article-body > p:first-child::first-line{
    font-weight: 523;
}

webinar, .webinar {
    padding-top: 100px;
    display: grid;
}

webinar > *:not(img), .webinar > *:not(img) {
    padding: 0 2rem;
}
.webinar-body {
    columns: 2 20ch;
    column-rule: 2px solid red;
    gap: 4rem;
    max-width: 100ch;
}

.mastermind {
  display: grid;
  grid-auto-flow: column;
}
.mastermind img {
  width:15em;
  margin:2em 5em 0 2em;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

.mastermind h1 {
  /* border:red 1px solid; */
  margin:2em 0 0 0;
  font-size: 3em;
}

.mastermind h2, .mastermind-p h4 {
  margin:2em 0 0 0;
}

.mastermind-p p {
  margin-top:1em;
}


options {
    margin: 3rem 0 0 3rem;
    width: min(92%, 97%);
    display:grid;
    grid-auto-flow: row;
    gap: 2rem
}


options p {
    text-align:left;
    display:block;
}

ul {
    list-style-type: none;
}

a.navbar-brand{
    font-size: 1.105em;
    color: hsl(272,100%,46.7%);
    letter-spacing: -1px;
    text-decoration: none;
}

.coname {
    display:block;
    margin: 1em 0 0 2em;
}

.coname2 {
    display: block;
    margin: -0.2em 0 0.5em 2em;
    padding: 0.8em;
    background-color: white;
    border-radius: 10px;
}

.footername {
    color: hsl(272,100%,46.7%);
}

.columns a.navbar-brand{
    font-size: 1em;
    color: hsl(272,100%,46.7%);
    letter-spacing: -1px;

}

.byline {
    display:block;
    font-weight: bold;
    margin: 1em 0 0 2em;
}

.logo-spot {
    font-weight: bold;
    color: hsl(302.4,100%,48.8%);
}

header {
    background: hsla(0, 0%, 96%, 100); /* background: hsla(50, 100%, 50%, 1); */
    text-align: center;
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 5rem;

}

nav {
    position: absolute;
    text-align: left;
    top: 100%;
    left: 0;
    background: hsla(50, 100%, 50%, 1);
    width: 100%;
    transform: scale(1,0);
    transform-origin: top;
    transition: transform 400ms ease-in-out;
}

nav ul {
    margin:0;
    padding:0;
    list-style:none;
}

nav li{
    margin-bottom: 1em;
    margin-left: 1em;
}

a.review-link {
  text-decoration: none;
  color: white;
  transition: opacity 150ms ease-in-out;
}
a.review-link:hover {
  color: white;
  text-decoration: underline;
  transition: opacity 150ms ease-in-out;
}
nav a {
    font-size:1rem;
    text-decoration: none;
    text-transform: uppercase;
    opacity: 0;
    transition: opacity 150ms ease-in-out;
    color: black;
}

nav a:hover {
    color: #000;
}

.nav-toggle:checked ~ nav {
    display: block;
    transform: scale(1,1);
}

.nav-toggle {
    display: none;
}

.nav-toggle-label {
    position: absolute;
    top:0;
    left: 0;
    margin-left: 1em;
    height: 100%;
    display:flex;
    align-items: center;
}

.nav-toggle-label span,
.nav-toggle-label span::before,
.nav-toggle-label span::after {
    display: block;
    background: black;
    height:2px;
    width:2em;
    border-radius: 2px;
    position: relative;
}

.nav-toggle-label span::before,
.nav-toggle-label span::after {
    content: '';
    position:absolute;
}
.nav-toggle-label span::before {
    bottom: 7px;
}
.nav-toggle-label span::after {
    top: 7px;
}

.nav-toggle:checked ~ nav a {
    opacity: 1;
    transition: opacity 250ms ease-in-out 250ms;
}


.columns {
    font-size: 1rem;
    padding-top: 3;
    color: hsl(0,0%,66.3%);
    margin: 2rem auto;
    width: minmax(40%, 80%);
    display:grid;
    grid-auto-flow: row;
    justify-self:stretch;
}

    .columns ul li a {
        text-decoration: none;
        color: hsl(0,0%,66.3%);
    }

        .columns ul li a:visited {
            text-decoration: none;
            color: hsl(0,0%,66.3%);
        }

footer {
    background: hsla(0, 0%, 96%, 100); /*background: hsla(50, 100%, 50%, 1);*/
    z-index:1;
}

.footer-copyright {
    text-align: center;
    padding: 5;
    color: hsl(0,0%,66.3%);
    background: hsl(45,100%,93.7%);
    z-index:999;
}
.footer-location {
    display:block;
    margin: 0.5em 0 0 2.2em;
}

.listHeading {
    display:block;
    font-weight: 750;
    margin:1rem 0 1rem 0;
}

.card-quickstart {
    border: 1.5rem solid black;
    color: hsl(0 0% 100%);
    background-image: url('https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/yuvraj-singh-Ztkd0xd_kwY-unsplash+(1).jpg');
    background-size: cover;
    padding: 5rem 0 0 0;
    max-width: 50em;
    border-radius: 0.5rem;
    overflow: hidden;
    transition: transform 500ms ease;
}

.card-quickstart:hover,
.card-quickstart:focus-within {
        transform: scale(1.05);
}

.card-register {
    border: 1.5rem solid black;
    color: hsl(0 0% 100%);
    background-image: url('https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/close-up-of-business-womans-hand-writing-signature-4RQ43LG+(1).jpg');
    background-size: cover;
    padding: 5rem 0 0 0;
    max-width: 50em;
    border-radius: 0.5rem;
    overflow: hidden;
    transition: transform 500ms ease;
}

    .card-register:hover,
    .card-register:focus-within {
        transform: scale(1.05);
    }

.card-membership {
    border: 1.5rem solid black;
    color: hsl(0 0% 100%);
    background-image: url(https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/sigmund-118x210-01.jpg);
    background-size: cover;
    padding: 5rem 0 0 0;
    max-width: 50em;
    border-radius: 0.5rem;
    overflow:hidden;
    transition: transform 500ms ease;
}
.card-membership:hover,
.card-membership:focus-within {
    transform: scale(1.05);
}

.card-book-resources {
    border: 1.5rem solid black;
    color: hsl(0 0% 100%);
    background-image: url(https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/cloud-storage-118x210-01.jpg);
    background-size: cover;
    padding: 5rem 0 0 0;
    max-width: 50em;
    border-radius: 0.5rem;
    overflow:hidden;
    transition: transform 500ms ease;
}

.card-book-resources:hover,
.card-book-resources:focus-within {
    transform: scale(1.05);
}

.card-esormabooks {
    border: 1.5rem solid black;
    color: hsl(0 0% 100%);
    background-image: url(https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/aaron-burden-118x210.jpg);
    background-size: cover;
    padding: 5rem 0 0 0;
    max-width: 50em;
    border-radius: 0.5rem;
    overflow:hidden;
    transition: transform 500ms ease;
}

.card-esormabooks:hover,
.card-esormabooks:focus-within {
    transform: scale(1.05);
}

.card-training {
    border: 1.5rem solid black;
    color: hsl(0 0% 100%);
    background-image: url(https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/taking-118X210-01.jpg);
    background-size: cover;
    padding: 5rem 0 0 0;
    max-width: 50em;
    border-radius: 0.5rem;
    overflow:hidden;
    transition: transform 500ms ease;
}

.card-training:hover,
.card-training:focus-within {
    transform: scale(1.05);
}

.card-mastermind {
    border: 1.5rem solid black;
    color: hsl(0 0% 100%);
    background-image: url(https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/masterminds-118x210-01.jpg);
    background-size: cover;
    padding: 5rem 0 0 0;
    max-width: 50em;
    border-radius: 0.5rem;
    overflow:hidden;
    transition: transform 500ms ease;
}

.card-mastermind:hover,
.card-mastermind:focus-within {
    transform: scale(1.05);
}

.card-webinars {
    border: 1.5rem solid black;
    color: hsl(0 0% 100%);
    background-image: url(https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/sharing-118x210-01.jpg);
    background-size: cover;
    padding: 5rem 0 0 0;
    max-width: 50em;
    border-radius: 0.5rem;
    overflow:hidden;
    transition: transform 500ms ease;
}

.card-webinars:hover,
.card-webinars:focus-within {
    transform: scale(1.05);
}

.card-fast {
    border: 1.5rem solid black;
    color: hsl(0 0% 100%);
    background-image: url(https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/businessman-118X210-01.jpg);
    background-size: cover;
    padding: 5rem 0 0 0;

    max-width: 50em;
    border-radius: 0.5rem;
    overflow:hidden;
    transition: transform 500ms ease;
}

.card-fast:hover,
.card-fast:focus-within:focus-within {
    transform: scale(1.05);
}

.card-reasons {
    border: 1.5rem solid black;
    color: hsl(0 0% 100%);
    background-image: url(https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/coworkers-118x210-01.jpg);
    background-size: cover;
    padding: 5rem 0 0 0;

    max-width: 50em;
    border-radius: 0.5rem;
    overflow:hidden;
    transition: transform 500ms ease;
}

.card-reasons:hover,
.card-reasons:focus-within {
    transform: scale(1.05);
}

.card-gifts {
    border: 1.5rem solid black;
    color: hsl(0 0% 100%);
    background-image: url(https://s3.eu-west-2.amazonaws.com/esorma.com/images/appImages/diversity-118x210-01.jpg);
    background-size: cover;
    padding: 5rem 0 0 0;

    max-width: 50em;
    border-radius: 0.5rem;
    overflow:hidden;
    transition: transform 500ms ease;
}

.card-gifts:hover,
.card-gifts:focus-within {
    transform: scale(1.05);
}

.card-content {
    --padding: 1.5rem;
    padding: var(--padding);
    background: linear-gradient(
        hsl(0 0% 0% / 0),
        hsl(20 0% 0% / 0.3) 20%,
        hsl(0 0% 0% / 1));
}

.card-title {
    position: relative;
    max-width: 100%;
    width: max-content;
    font-size: 80%;
}

.card-body {
    font-size: 55%;
}

.card-title::after {
    content:'';
    position: absolute;
    height: 4px;
    width: 100%;
    left: calc(var(--padding) * -1);
    bottom: 0;
    width: calc(100% + var(--padding));
    background: hsl(45,100%,93.7%);

    transform-origin: left;
    transition: transform 500ms ease;
}

.card-quickstart:hover .card-title::after,
.card-quickstart:focus-within .card-title::after {
    transform: scaleX(1);
}

.card-register:hover .card-title::after,
.card-register:focus-within .card-title::after {
    transform: scaleX(1);
}

.card-membership:hover .card-title::after,
.card-membership:focus-within .card-title::after {
    transform: scaleX(1);
}

.card-book-resources:hover .card-title::after,
.card-book-resources:focus-within .card-title::after {
    transform: scaleX(1);
}

.card-esormabooks:hover .card-title::after,
.card-esormabooks:focus-within .card-title::after {
    transform: scaleX(1);
}

.card-training:hover .card-title::after,
.card-training:focus-within .card-title::after {
    transform: scaleX(1);
}

.card-mastermind:hover .card-title::after,
.card-mastermind:focus-within .card-title::after {
    transform: scaleX(1);
}

.card-webinars:hover .card-title::after,
.card-webinars:focus-within .card-title::after {
    transform: scaleX(1);
}

.card-fast:hover .card-title::after,
.card-fast:focus-within .card-title::after {
    transform: scaleX(1);
}

.card-reasons:hover .card-title::after,
.card-reasons:focus-within .card-title::after {
    transform: scaleX(1);
}

.card-gifts:hover .card-title::after,
.card-gifts:focus-within .card-title::after {
    transform: scaleX(1);
}

@media (hover) {
    .card-content {
        transform: translateY(55%);
        transition: transform 500ms ease;
    }

    .card-quickstart:hover .card-content,
    .card-quickstart:focus-within .card-content {
        transform: translateY(0);
        transition-delay: 500ms;
    }

    .card-register:hover .card-content,
    .card-register:focus-within .card-content {
        transform: translateY(0);
        transition-delay: 500ms;
    }

    .card-membership:hover .card-content,
    .card-membership:focus-within .card-content {
        transform: translateY(0);
        transition-delay: 500ms;
    }

    .card-book-resources:hover .card-content,
    .card-book-resources:focus-within .card-content {
        transform: translateY(0);
        transition-delay: 500ms;
    }

    .card-esormabooks:hover .card-content,
    .card-esormabooks:focus-within .card-content {
        transform: translateY(0);
        transition-delay: 500ms;
    }

    .card-training:hover .card-content,
    .card-training:focus-within .card-content {
        transform: translateY(0);
        transition-delay: 500ms;
    }

    .card-mastermind:hover .card-content,
    .card-mastermind:focus-within .card-content {
        transform: translateY(0);
        transition-delay: 500ms;
    }

    .card-webinars:hover .card-content,
    .card-webinars:focus-within .card-content {
        transform: translateY(0);
        transition-delay: 500ms;
    }

    .card-fast:hover .card-content,
    .card-fast:focus-within .card-content {
        transform: translateY(0);
        transition-delay: 500ms;
    }

    .card-reasons:hover .card-content,
    .card-reasons:focus-within .card-content {
        transform: translateY(0);
        transition-delay: 500ms;
    }

    .card-gifts:hover .card-content,
    .card-gifts:focus-within .card-content {
        transform: translateY(0);
        transition-delay: 500ms;
    }

    .card-quickstart:focus-within .card-content {
        transition-duration: 0ms;
    }

    .card-register:focus-within .card-content {
        transition-duration: 0ms;
    }

    .card-membership:focus-within .card-content {
        transition-duration: 0ms;
    }

    .card-book-resources:focus-within .card-content {
        transition-duration: 0ms;
    }

    .card-esormabooks:focus-within .card-content {
        transition-duration: 0ms;
    }

    .card-training:focus-within .card-content {
        transition-duration: 0ms;
    }

    .card-mastermind:focus-within .card-content {
        transition-duration: 0ms;
    }

    .card-webinars:focus-within .card-content {
        transition-duration: 0ms;
    }

    .card-fast:focus-within .card-content {
        transition-duration: 0ms;
    }

    .card-reasons:focus-within .card-content {
        transition-duration: 0ms;
    }

    .card-gifts:focus-within .card-content {
        transition-duration: 0ms;
    }

    .card-content > *:not(.card-title) {
        opacity: 0;
        transition: opacity 500ms linear;
    }


    .card-quickstart:hover .card-content > *:not(.card-title),
    .card-quickstart:focus-within .card-content > *:not(.card-title) {
        opacity: 1;
        transition-delay: 750ms;
    }

    .card-register:hover .card-content > *:not(.card-title),
    .card-register:focus-within .card-content > *:not(.card-title) {
        opacity: 1;
        transition-delay: 750ms;
    }

    .card-membership:hover .card-content > *:not(.card-title),
    .card-membership:focus-within .card-content > *:not(.card-title)  {
        opacity: 1;
        transition-delay: 750ms;
    }

    .card-book-resources:hover .card-content > *:not(.card-title),
    .card-book-resources:focus-within .card-content > *:not(.card-title)  {
        opacity: 1;
        transition-delay: 750ms;
    }

    .card-esormabooks:hover .card-content > *:not(.card-title),
    .card-esormabooks:focus-within .card-content > *:not(.card-title)  {
        opacity: 1;
        transition-delay: 750ms;
    }

    .card-training:hover .card-content > *:not(.card-title),
    .card-training:focus-within .card-content > *:not(.card-title)  {
        opacity: 1;
        transition-delay: 750ms;
    }

    .card-mastermind:hover .card-content > *:not(.card-title),
    .card-mastermind:focus-within .card-content > *:not(.card-title)  {
        opacity: 1;
        transition-delay: 750ms;
    }

    .card-webinars:hover .card-content > *:not(.card-title),
    .card-webinars:focus-within .card-content > *:not(.card-title)  {
        opacity: 1;
        transition-delay: 750ms;
    }

    .card-fast:hover .card-content > *:not(.card-title),
    .card-fast:focus-within .card-content > *:not(.card-title)  {
        opacity: 1;
        transition-delay: 750ms;
    }

    .card-reasons:hover .card-content > *:not(.card-title),
    .card-reasons:focus-within .card-content > *:not(.card-title)  {
        opacity: 1;
        transition-delay: 750ms;
    }

    .card-gifts:hover .card-content > *:not(.card-title),
    .card-gifts:focus-within .card-content > *:not(.card-title)  {
        opacity: 1;
        transition-delay: 750ms;
    }

    .card-title::after {
        transform: scaleX(0);
    }

}

.card-button {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    color: hsl(0 0% 0%);
    background-color: hsl(45,100%,93.7%);
    padding: 0.5em 1.25em;
    border-radius: 0.25rem;

}

.card-button:hover,
.card-button:focus {
    background-color: hsl(0 0% 100%);
}

.form-container {
    min-height: 100vh;
    display:grid;
    place-items:center;
    font-family: 'Heebo', sans-serif;
}

.form {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    overflow: hidden;
    width: 80%;
    max-width: 300px;
}

.form-item {
    display: flex;
    flex-direction: column;
}

.form-input,
.form-button {
    font-family: inherit;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 10px;
    letter-spacing: 1.2px;
    border: 0;
}

.form-input {
    padding: 1.5em;
    outline: none;
}

.form-input::placeholder {
    opacity: 0.35;
}

.form-input:focus::placeholder {
    color: #006194;
    opacity: 0.85;
}

.form-item:first-of-type {
    position: relative;
}

.form-item:first-of-type::after {
    content: '';
    position: absolute;
    right:0;
    left: 0;
    bottom: 0;
    height: 1px;
    background: rgba(0,0,0,0.1);
}

.form-button {
    background: #006194;
    color: white;
    padding: 1.25em 2.25em;
}

@media (max-width: 799px) {
    .card-title {
        font-size: 120%;
    }
    .card-body {
        font-size: 100%;
    }
}
@media (min-width: 1020px) {
    .card-title {
        font-size: 95%;
    }
    .card-body {
        font-size: 90%;
    }
}

@media (min-width: 1170px) {
    .card-title {
        font-size: 120%;
    }
    .card-body {
        font-size: 100%;
    }
}

@media (min-width: 50rem) {
    article {
        grid-template-columns: min(40%, 40rem) 1fr;
    }
    webinar {
        grid-template-columns: min(40%, 40rem) 1fr;
    }
    .form {
        flex-direction: row;
        max-width: 50rem;
        border: 8px solid white;
        border-radius: 3em;
    }

    .form-button {
        border-radius: 3em;
        margin-left: auto;
    }

    .form-item {
        justify-content: center;
    }

    .form-item:last-of-type {
        flex-grow: 1;
    }

    .form-item:first-of-type::after {
        right: 0;
        top:0;
        bottom:0;
        left: initial;
        width: 1px;
        height: initial;
    }

    article > img {
        grid-row: 1 / 4;
        height:100%;
        object-fit: cover;
    }

    article > *:not(img) {
        grid-column: 2 / -1;
    }

    webinar > img {
        grid-row: 1 / 4;
        height: 100%;
        object-fit: cover;
    }

    webinar > *:not(img) {
        grid-column: 2 / -1;
    }

    .columns {
        grid-auto-flow: column;
    }

    options {
        grid-auto-flow: column;
    }

    .nav-toggle-label {
        display: none;
    }

    header {
        display:grid;
        grid-template-columns: 0.5fr auto minmax(200px, 3fr) 0.5fr;
    }
    .limit {
        max-height: 18rem;
    }

    .navbar-brand {
        grid-column: 1 / 2;
    }

    nav {
        all: unset;
        grid-column: 3 / 4;
        display:flex;
        justify-content: flex-end;
        align-items: center;
    }

    nav ul {
        display: flex;
        justify-content: flex-end;

    }

    nav li {
        margin-left: 3em;
        margin-bottom:0;
    }

    nav a {
        opacity: 1;
        position: relative;
    }

    nav a::before {
        content:'';
        display:block;
        height: 5px;
        background: black;
        position: absolute;
        bottom: -0.75em;
        left: 0;
        right:0;
        transform: scale(0,1);

        transition: transform ease-in-out 250ms;
    }

    nav a:hover::before {
        transform: scale(1,1);
    }
}



@media (prefers-reduced-motion: reduce) {
    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
        transition-delay: 0ms !important;
    }
}
